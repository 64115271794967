import React from "react";
import ComingSoon from "./ComingSoon";

class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <ComingSoon />     
      </div>
    );
  }
}

export default Home;