import React from "react";

class ComingSoon extends React.Component {
  render() {
    return (
      <div className="comingSoon">
        <img src="./images/coming-soon.png" alt="Coming Soon" />        
      </div>
    );
  }
}

export default ComingSoon;